<template>
    <v-row no-gutters>
        <v-col cols="12">
            <v-row justify="center" class="pt-5">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                    <v-card elevation="2" class="my-6">
                        <v-app-bar color="indigo" dark flat dense>
                            <v-app-bar-title>Create new identity verification realm</v-app-bar-title>
                            <v-spacer/>
                            <!-- <v-btn icon @click="openCreateEnterpriseDialog">
                                <font-awesome-icon :icon="['fas', 'plus']" style="font-size: 20px;" fixed-width/>
                            </v-btn> -->
                        </v-app-bar>
                        <v-form @submit.prevent="onSubmitForm" @keyup.enter.prevent="onSubmitForm" class="mx-5 mt-5">
                            <v-text-field
                                ref="newRealmNameInput"
                                v-model="newRealmName"
                                label="Display Name"
                                color="indigo"
                                hint="The name to display prominently to users"
                                type="text"
                                outlined
                                dense
                            >
                            </v-text-field>
                            <v-text-field
                                class="mt-4"
                                ref="newRealmAliasInput"
                                v-model="newRealmAlias"
                                label="Alias (code name)"
                                color="indigo"
                                hint="A meaningful alias to use when referencing this realm in code or settings"
                                type="text"
                                outlined
                                dense
                                @focus="newRealmAliasAuto = false"
                            >
                            </v-text-field>
                            <!-- <v-radio-group label="Deployment mode" mandatory v-model="newRealmDevelopmentMode">
                                <v-radio value="production">
                                    <template #label>
                                        Production (requires secure connection with HTTPS)
                                    </template>
                                </v-radio>
                                <v-radio value="development">
                                    <template #label>
                                        Development (allows insecure connection with HTTP)
                                    </template>
                                </v-radio>
                            </v-radio-group> -->
                            <!-- TODO: need to consider IF this should only be editable if enterprise has a paid subscription -->
                            <v-checkbox v-model="newRealmDevelopmentMode">
                                <template #label>
                                    Development mode
                                    <v-tooltip top>
                                        <template #activator="{ on, attrs }">
                                            <font-awesome-icon :icon="['fas', 'exclamation-triangle']" class="amber--text ml-2" v-bind="attrs" v-on="on"/>
                                        </template>
                                        <p class="mb-0">Allows use of HTTP connections.</p>
                                        <p class="mb-0">Allows simulation of verification results.</p>
                                        <p class="mb-0">Developers &amp; testers only!</p>
                                        <p class="mb-0">You can turn off development mode later.</p>
                                    </v-tooltip>
                                </template>
                            </v-checkbox>
                            <!-- TODO: should only be editable if enterprise has a paid subscription -->
                            <v-checkbox v-model="newRealmQuickVerification">
                                <template #label>
                                    Quick verification
                                    <v-tooltip top>
                                        <template #activator="{ on, attrs }">
                                            <font-awesome-icon :icon="['fas', 'info-circle']" class="indigo--text ml-2" v-bind="attrs" v-on="on"/>
                                        </template>
                                        <p class="mb-0">Skip final confirmation when only one verification item is requested.</p>
                                        <p class="mb-0">This is a better user experience in most cases.</p>
                                    </v-tooltip>
                                </template>
                            </v-checkbox>
                        </v-form>
                        <v-card-actions>
                            <v-spacer/>
                            <v-btn elevation="4" class="indigo white--text" @click="createRealm" :disabled="!isFormComplete">
                                <font-awesome-icon :icon="['fas', 'check']" fixed-width/>
                                <span class="ml-2">Create</span>
                            </v-btn>
                            <v-spacer/>
                        </v-card-actions>
                    </v-card>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<style scoped>

</style>

<script>
import { mapState } from 'vuex';
import { isValidName, compact } from '@/sdk/input';

export default {
    data: () => ({
        createRealmForm: null,
        newRealmName: null,
        newRealmNameRules: [
            (v) => !!v || 'Display name is required',
            (v) => !v || isValidName(compact(v)) || 'Realm name is required',
        ],
        newRealmAlias: null,
        newRealmAliasRules: [
            (v) => !!v || 'Alias is required',
            (v) => !v || isValidName(compact(v)) || 'Realm alias is required', // TODO: change validation to isValidRealmAlias
        ],
        newRealmAliasAuto: true,
        newRealmDevelopmentMode: false,
        newRealmQuickVerification: false,
        submitTimestamp: null,
    }),
    computed: {
        ...mapState({
            isAuthenticatedReady: (state) => state.isReady,
            session: (state) => state.session,
        }),
        isAuthenticated() {
            return this.session.isAuthenticated;
        },
        isFormComplete() {
            const name = this.newRealmName && isValidName(compact(this.newRealmName));
            const alias = this.newRealmAlias && isValidName(compact(this.newRealmAlias));
            return name && alias;
        },
    },
    watch: {
        newRealmName(newValue, oldValue) {
            if (newValue && newValue !== oldValue && this.newRealmAliasAuto) {
                this.newRealmAlias = compact(newValue);
            }
        },
    },
    methods: {
        async createRealm() {
            try {
                this.$store.commit('loading', { createRealm: true });
                console.log('createRealm');
                const request = {
                    // enterprise_id: this.$route.params.enterpriseId, // TODO: shouldn't be needed
                    display_name: this.newRealmName,
                    alias: this.newRealmAlias,
                    is_quick: this.newRealmQuickVerification,
                    is_dev: this.newRealmDevelopmentMode,
                };
                const response = await this.$client.cryptiumId(this.$route.params.enterpriseId).realm.create(request);
                console.log('createRealm response: %o', response);
                const { isCreated, id, error } = response;
                if (isCreated && id) {
                    this.$router.replace({ name: 'cryptium_id-realm-dashboard', params: { enterpriseId: this.$route.params.enterpriseId, realmId: id } });
                } else if (error) {
                    console.error(`failed to create realm: ${JSON.stringify(error)}`);
                    this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to create realm' });
                } else {
                    this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to create realm' });
                }
            } catch (err) {
                console.log('create realm failed', err);
                if (err.response?.status === 409) {
                    this.$bus.$emit('snackbar', { type: 'error', headline: 'There is already a realm with the same alias', message: 'Change the alias and try again' });
                } else {
                    this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to create realm' });
                }
            } finally {
                this.$store.commit('loading', { createRealm: false });
            }
        },
        onSubmitForm() {
            if (Number.isInteger(this.submitTimestamp) && this.submitTimestamp + 500 > Date.now()) {
                return;
            }
            this.submitTimestamp = Date.now();
            // if (this.$refs.createRealmFormRef.validate()) {
            //     this.createRealm();
            // }
            this.createRealm();
        },

        async addRealm() {
            const realm = { id: this.addRealmId, label: this.addRealmChoices.find((item) => item.value === this.addRealmId).text };
            this.list.push(realm);
            this.$emit('added-realm', realm);
            this.displayCreateRealm = false;
            this.addRealmId = null;
        },
        activate(ref) {
            const inputRef = Array.isArray(this.$refs[ref]) ? this.$refs[ref][0] : this.$refs[ref];
            if (inputRef) {
                // more than one way to do it:
                // 1. inputRef.focus();
                // 2. const inputElement = inputRef.$el.querySelector('input'); inputElement.focus();
                // 3. const inputElement = inputRef.$el.querySelector('input'); document.getElementById(inputElement.id).focus()
                inputRef.focus();
            }
        },
    },
    mounted() {
        this.$nextTick(() => {
            setTimeout(() => { this.activate('newRealmNameInput'); }, 1);
        });
    },
};
</script>
